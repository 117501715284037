.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Cards {
  min-height: 100vh;

 background-color: #282c34;
}

.Card {
  margin: 20px;
  max-width: 340px;
  min-width: none;
}

.home {
  margin-top: 2px;
  position:absolute;
  right: 10px;
  top: 5px;
}

.Input {
  width: 168px;
}

.Navigation {
  justify-content: end;
  background-color: #282c34;
}

.Navbutton {
padding-left: 20px;
}

.Navigation-Buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px;
}

.Popover {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
